@font-face {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 100;
    src: url('../containers/DefaultLayout/fonts/Nexa-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Nexa Semibold';
    font-style: normal;
    font-weight: 100;
    src: url('../containers/DefaultLayout/fonts/Manrope-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope Regular';
    font-style: normal;
    font-weight: 100;
    src: url('../containers/DefaultLayout/fonts/Manrope-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope Medium';
    font-style: normal;
    font-weight: 100;
    src: url('../containers/DefaultLayout/fonts/Manrope-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope Bold';
    font-style: normal;
    font-weight: 100;
    src: url('../containers/DefaultLayout/fonts/Manrope-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope ExtraBold';
    font-style: normal;
    font-weight: 100;
    src: url('../containers/DefaultLayout/fonts/Manrope-ExtraBold.ttf') format('truetype');
}

.app {
  background: #ffffff;
}
.PhoneInputInput{
    height: 40px !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
}
.app-footer {
    flex: 0 0 50px;
    margin-left: 0px;
}

.app-footer {
    background: #f3f3f3 !important;
}

.app-header, .app-footer, .sidebar, .main, .aside-menu {
    transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.app-footer {
    right: 2%;
    width: 102%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 1rem;
    color: #23282c;
    background: #f0f3f5;
    border-top: 1px solid #c8ced3;
}
.app-footer-home{
    right: 0% !important;
    width: 100% !important;
}

.footer-right .nav {
    display: flex !important;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.footer-aline-pow{
text-align: center;
display: inline;
}
.power_by{
    width: 250px;
    height: 30px;
    margin-left: 72%;
    border: #FF4D4D solid 2px;
    align-items: center;
    text-align: center;
    float: left;
    
      }
.title-terms-policy {
    color: #0A4259;
    font-size: 16px !important;
    font-weight: 800 !important;
}
.box-viaje-text{
    color: #f0f3f5 !important;
}
.title-final-terms-policy {
    color: #2c4664;
    font-size: 18px !important;
    font-weight: 800 !important;
}

.text-terms-policy{
    color: #494C52;
    font-size: 15px;
    font-weight: 500 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
    color: #02c36b !important;
}
.table-term-policy {
    font-family: arial, sans-serif;
    border-collapse: collapse !important;
    width: 95%;
}

.table-term-policy td, .table-term-policy th {
    border: none !important;
    text-align: left;
    padding: 8px;

}

.table-term-policy tr:nth-child(even) {
    background-color: #dddddd;
}

footer img {
    height: 41px;
}

body {
    font-family: 'Manrope Medium';
    color: #5C6066;
    font-size: 16px;
    line-height: 22px;
    background: #F7F7F7;
}

.family-nexa-bold {
    font-family: 'Nexa Bold';
    font-weight: 300;
}

.family-nexa-semibold {
    font-family: 'Nexa Semibold';
    font-weight: 300;
}

.family-manrope-extrabold {
    font-family: 'Manrope ExtraBold';
    font-weight: 300;
}

.family-manrope-bold {
    font-family: 'Manrope Bold';
    font-weight: 300;
}

.family-manrope-regular {
    font-family: 'Manrope Regular';
    font-weight: 300;
}

.color-regular {
    color: #5C6066 !important;
}

.color-blue {
    color: #0A4259 !important;
}

.color-red {
    color: #FF4D4D !important;
}

.color-blue-green {
    color: #42ADD4 !important;
}

.color-black {
    color: #292B2E;
}

.color-gray {
    color: #8F9094 !important;
}

.checkbox-row{
    content: ' ';
    border-radius: 4px;
    border: 2px solid rgba(73, 76, 82, 0.6);
    width: 24px;
    height: 24px;
    margin: 0;
    top: 0;
    left: 0;
    position: relative;
    display: block;
}

.checkbox-row-selected{
    content: '\2714';
    text-shadow: none;
    position: relative;
    top: 0;
    left: 0;
    background: #0A4259;
    border: 2px #0A4259 solid;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin: 0;
    display: block;
}

.carousel-card div{
    box-shadow: none!important;
}

.MuiTypography-body1 {
    color: #434343 !important;
    font-size: 16px !important;
}

a {
    color: #3399FF
}

h1, h2, h3, h4, h5, h6, .text-bold, .text-semibold, .text-medium {
    font-weight: 300 !important;
    font-size: 16px !important;
}

.w-100 {
    width: 100% !important;
    max-width: 100% !important;
}

.mw-400 {
    max-width: 400px !important;
}

.w-auto {
    width: auto !important;
    max-width: none !important;
}



.font-9 {
    font-size: 8px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-14 {
    color: #434343;
    font-size: 14px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-32 {
    font-size: 32px !important;
}

.border-radius-20{
    border-radius: 20px;
}

.circle-blue{
    height: 25px;
    width: 25px;
    background: #0A4259;
    border-radius: 12px;
}

.circle-green{
    height: 25px;
    width: 25px;
    background: #34b576;
    border-radius: 12px;
}

.circle-red{
    height: 25px;
    width: 25px;
    background: #ce2424;
    border-radius: 12px;
}

.circle-yellow{
    height: 25px;
    width: 25px;
    background: #ffcd00;
    border-radius: 12px;
}

.circle-disabled{
    height: 25px;
    width: 25px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
}

.color-grey{
    color: #ccc;
}

.btn-attach{
    color: #dad9d9 !important;
    background: #ffffff;
    width: 100%;
    box-shadow: none;
    border: 1px solid #dad9d9 !important;
}

.attach-border{
    border-bottom: 1px solid;
}

.text-limit{
    width: 20ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
 }

.btn-primary-small{
    background: #F26A4B;
    color: #fff;
    border: none;
    box-shadow: 0px 16px 14px rgba(254, 98, 0, 0.2);
    border-radius: 4px;
    letter-spacing: 0.78px;
    text-transform: uppercase;
    font-size: 10px;
    padding: 0 20px;
    font-family: 'Manrope Bold';
    transition: 0.3s;
    padding: 10px 10px;
}


.outline-smart{
    border: 2px solid rgb(0 60 127) !important;
    color: rgb(0 60 127) !important;
    font-weight: bold !important;
}

.outline-smart i{
    font-weight: bold !important;
}

.fixed-help{
    background-color: #fff !important;
    position: fixed !important;
    bottom: 110px !important;
    right: 20px !important;
}

.fixed-help-middle{
    background-color: #fff !important;
    position: fixed !important;
    bottom: 50vh !important;
    right: 10px !important;
    z-index: 1000000;
}

.fixed-help-middle-100{
    background-color: #fff !important;
    position: fixed !important;
    bottom: 0vh !important;
    width: 100vw !important;
    z-index: 1000000;
    right: 0.5px;
}

.fixed-help-back{
    background-color: #fff !important;
    position: fixed !important;
    top: 100px !important;
    left: 50px !important;
}

.btn-primary-small:disabled{
    background: hsla(23, 100%, 50%, 0.281);

}

.text-normal{
    font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}


.btn.btn-primary:disabled {
  cursor: default !important;
}

.grey {
    background: #F9F9F9 !important;
}

.bg-grey-dark {
    background: #A0A0A0;
}

.green {
    background: #00d7c5;
}

.red {
    background: #ff5142 !important;
}

.blue {
    background: #3283ff;
}

.green-blue {
    background: #72BECC !important;
}

.color-red {
    color: #ff5142 !important;
}

.color-blue {
    color: #0A4259 !important;
}

.color-dark {
    color: #434343 !important;
}

.dark-blue {
    background: #4254b0;
}

.text-green-blue {
    color: #72BECC;
}

.text-grey {
    color: #6d7278;
}

.text-grey-light {
    color: #A6A6A6;
}

.text-grey-lighter {
    color: #DFDFDF;
}

.color-white{
    color: #fff;
}

.line-14 {
    line-height: 14px;
}

.line-18 {
    line-height: 18px;
}

.line-20 {
    line-height: 20px;
}

.ml-10 {
    margin-left: 10px;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-100 {
    margin-top: 100px;
}

.mb-21 {
    margin-bottom: 21px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-70 {
    margin-bottom: 70px;
}

.pb-150 {
    padding-bottom: 150px !important;
}

.w-250 {
    width: 250px !important;
}

.w-180 {
    width: 180px !important;
}

.h-40 {
    height: 40px;
}

.h-255 {
    height: 255px !important;
}

.h-170 {
    height: 170px !important;
}

.form-group {
    margin-bottom: 24px;
}

.line-size-18 {
    line-height: 18px;
    display: block;
}

.main{
    background: #F9F9F9 !important;
}

header.header {
    height: 113px;
    display: flex;
    background-color: #FFFFFF;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
}

.site-container {
    padding: 100px 100px 0px 60px;
    width: 100%;
}

header .site-container, footer .site-container {
    padding: 0 30px;
    margin: 0;
}

.main-sidebar {
    background: #3283ff;
    width: 90px;
    position: fixed;
    height: 100%;
    z-index: 999;
    top: 113px;
    left: 0px;
}

.main-sidebar ul {
    margin: 0;
    padding: 40px 0 0 0;
}

.main-sidebar ul li {
    padding: 15px 0;
    list-style: none;
    text-align: center;
}

.main-sidebar ul li a {
    text-decoration: none;
}

.main-sidebar ul li.active-option {
    background: #fff;
}

.main-sidebar ul li:last-child {
    margin-bottom: 0;
}

.main-sidebar ul li span {
    color: #FFFFFF;
    font-size: 12px;
    display: block;
    margin-top: 2px;
}

.main-sidebar ul li.active-option span {
    color: #3283ff;
}

.site-logo img {
    height: 51px;
}

.main-content {
    background: #F9F9F9;
    min-height: 900px;
    top: -100px;
    position: relative;
}

.button-state, .button-thin, .button-small, .button-large, .button-bordered-dark {
    border-width: 2px;
    border-style: solid;
    display: table;
    color: #fff;
    font-size: 16px;
    border-radius: 15px;
    cursor: pointer;
    border-color: transparent;
    transition: transform .2s;
}

.button-large, .button-medium, .button-bordered-dark, .bordered-button {
    min-width: 156px;
    height: 48px;
    line-height: 46px;
    border-radius: 5px;
    text-align: center;
    padding: 0 15px;
    transition: transform .2s;
}

.filter-drop .button-large, .filter-drop .bordered-button, .info-box .button-large, .info-box .bordered-button {
    min-width: auto;
}

.button-state {
    line-height: 15px;
    padding: 0 10px;
    border: none;
    height: 16px;
    display: inline-block;
    font-size: 12px;
    cursor: default;
}

.button-thin, .button-small {
    padding: 0 10px;
}

.button-small {
    font-size: 12px;
    line-height: 0;
    height: 17px;
    display: inline-flex;
    align-items: center;
    border: none;
}

.button-thin {
    cursor: default;
    line-height: 18px;
}

.button-state {
    line-height: 15px;
    padding: 0 10px;
    border: none;
    height: 16px;
    display: inline-block;
    font-size: 12px;
}

.bordered-button {
    background: transparent !important;
    border-style: solid;
    display: block;
    transition: transform .2s;
}

.button-bordered-dark {
    border: 1px #434343 solid;
    border-radius: 50px;
    padding: 0 10px;
    height: 40px;
    line-height: 39px;
    display: block;
    max-width: max-content;
}

.button-medium {
    height: 40px;
    line-height: 35px;
}

a.bordered-button.button-large {
    text-decoration: none;
}

.button-text {
    background: transparent;
    border: none;
    font-size: 14px;
    padding: 0;
}

.bordered-button.red {
    color: #ff5142;
    border-color: #ff5142;
}

.bordered-button.red:hover {
    color: #ff5142;
}

.bordered-button.green-blue {
    color: #72BECC;
    border-color: #72BECC;
    border-width: 2px;
}

.button-large:hover, .bordered-button:hover {
    transform: scale(1.03);
    text-decoration: none;
    transition: transform .2s;
}

a.button-large:hover {
    color: #34c2d0;
}

a.button-large-white:hover {
    color: #fff !important;
}


#wizard-continue a {
    color: #fff;
}

#wizard-continue a:hover, #wizard-continue:hover a {
    text-decoration: none;
}

.header-info {
    padding: 30px 50px;
    background-color: #F7F7F7;
    margin-top: 113px;
}

.simple-info img, .user-info img {
    width: 48px;
    border-radius: 16px;
}

.box-input-date input{
    font: inherit;
    background-color: rgb(244 244 244);
    border-bottom: 1px solid rgba(164,167,181,1);
    border-top: 0px;
    border-radius: 12rem;
    width: 100%;
    -webkit-transition: all 0.1s linear,padding 0s,border 0s;
    /* transition: all 0.1s linear,padding 0s,border 0s; */
    display: inline-block;
    padding: 0 1rem;
    line-height: 2.5rem;
    height: 48px !important;
    color: rgba(42,48,57,1);
    font-size: 1rem;
    box-sizing: inherit;
    margin: 0;
    padding-left: 1rem;
    padding-right: 2.35rem;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    position: relative; 
}

.box-input-date svg{
    display: none;
}

.boton-cotizar:hover{
  color: #ffffff!important;
}

.boton-cerrar-sesion:hover{
  text-decoration: none !important;
}

.notification-badge, .selected-quantity span, .number-info {
    height: 16px;
    width: 24px;
    background: #ff5142;
    color: #FFF;
    display: table;
    text-align: center;
    position: absolute;
    border-radius: 8px;
    font-size: 11px;
    left: -12px;
}

.general-notifications .notification-badge {
    position: relative;
    left: auto;
}

.number-info {
    position: relative;
    left: auto;
}

.action-buttons {
    position: relative;
    display: flex;
    align-items: center;
}

.user-info-texts h3 {
    margin: 0 0 -5px 0;
}

.user-info-texts {
    margin: 0 20px 0 0;
}

.city-info span:first-child {
    font-weight: 600;
}

.city-info .separator {
    margin: 0 8px;
    width: 4px;
    height: 1px;
    background: #434343;
}

.tables-tabs .nav-link {
    padding: 0 40px 10px 40px;
    color: #A0A0A0;
    font-size: 16px;
    border: none;
}

.tables-tabs .nav-link:focus, .tables-tabs .nav-link:hover {
    border: none;
}

.tables-tabs .nav-item {
    margin-bottom: 0;
    border-bottom: 2px #8D92AA solid;
}

.tables-tabs .nav-link.active {
    color: #3283FF;
    border: none;
    border-radius: 0;
    position: relative;
    background: transparent;
}

.container-tables-tabs {
    background: #F7F7F7;
    padding: 0 50px;
    height: 50px;
    display: flex;
    align-items: flex-end;
    border-bottom: 2px #E2E8EE solid;
}

.tables-tabs .nav-link.active::after {
    content: "";
    background: #3283FF;
    height: 2px;
    border-radius: 0;
    bottom: -2px;
    position: absolute;
    width: 100%;
    left: 0;
}

.tables-tabs {
    border-bottom: none;
    position: relative;
    top: 2px;
}

.datatables_filter {
    display: none;
}

.sub-title-categoria{
    font-weight: 800 !important;
    letter-spacing: -0.3px;
    color: rgb(41, 43, 46);
    line-height: 25px;
    font-size:  18px !important;
}

.hr-categoria{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px dashed #99B1CC !important;
}

.col-space-between{
    display: flex;
    justify-content: space-between;
}

input.form-control::placeholder, textarea.form-control::placeholder {
    opacity: 1;
    color: #A2A2A2;
}

.search-box input.form-control::placeholder {
    color: #434343;
}

.search-box input.form-control {
    background: transparent !important;
    padding: 0 !important;
    height: auto !important;
}

.form-control:focus {
    box-shadow: none;
}

.filter-field, .search-box, .group-field {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 230px;
    margin-right: 80px;
}

.group-field.bordered, .search-box.bordered {
    border-bottom: 2px #434343 solid;
    padding: 0 25px 20px 6px;
    width: 270px;
}

.search-box {
    width: 330px;
}

.filter-box img, .search-box img, .group-field img {
    height: 20px;
    margin-right: 8px;
}

.filter-field::after, .group-field:after {
    content: "";
    display: inline-block;
    width: 14px;
    height: 8px;
    background: url(../images/icons/icon-angle-down.png) no-repeat center / cover;
    position: absolute;
    top: 9px;
    right: 10px;
}

.filter-drop, .groupby-drop {
    max-width: 360px;
    position: absolute;
    background: #fff;
    z-index: 99999;
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.15);
    padding: 20px 25px;
    margin-top: 0px;
    display: none;
    border-radius: 0 0 16px 16px;
    margin-top: 15px;
    width: 318px;
}

.groupby-drop {
    width: 270px;
    padding: 20px;
}

.filter-drop.open, .groupby-drop.open {
    display: block;
}

.groupby-drop li {
    font-weight: 300;
    border-bottom: 1px #CACACA solid;
    width: 100%;
    padding-bottom: 8px;
    cursor: pointer;
}

.groupby-drop li a {
    color: #434343;
}

.action-buttons img {
    height: 23px;
    width: auto;
}

.action-buttons button {
    margin-left: 25px;
}

.action-buttons button:first-child {
    margin-left: 0;
}

.table-head-actions {
    margin: 0 0 30px;
}

table.datatable {
  overflow: auto;
  padding: 0 2px;
}
table.datatable thead .sorting_asc, table.datatable thead .sorting_desc, table.datatable thead .sorting, table.datatable thead .sorting_asc, table.datatable thead .sorting_desc, table.datatable thead .sorting_asc_disabled, table.datatable thead .sorting_desc_disabled {
    background-size: 23px;
    background-position: 10px !important;
    padding: 2px 15px 2px 34px;
    font-size: 16px;
    font-weight: 300;
}

table.datatable thead .sorting {
    padding: 0 15px 0 15px;
    background-image: none;
}
.opciones{
    cursor: pointer;
}
table.datatable thead .sorting_asc, table.datatable thead .sorting_desc {
    background-image: url("/assets/images/icons/icon-arrow-up.png");
    background-repeat: no-repeat;
}

table.datatable thead .sorting_desc {
    background-image: url("/assets/images/icons/icon-arrow-down.png");
}

table.datatable thead th, table.datatable thead td {
    padding: 8px 15px 8px;
}

table.datatable, .section-grey {
    background: #f9f9f9 !important;
}

table.datatable thead th, table.datatable thead td {
    border-bottom: none;
}

table.datatable tbody th, table.datatable tbody td {
    padding: 30px 15px;
    vertical-align: top;
}

tr.group td {
    padding: 0 15px !important;
    background: #f9f9f9;
    position: relative;
}

table.datatable tbody tr {
    background-color: #fff ;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.tr-filter {
  background-color: #f3f3f3 !important;
}

table.datatable tbody tr.odd>.sorting_1, table.datatable.order-column.stripe tbody tr.odd>.sorting_1 {
    background-color: transparent;
}

table.datatable.no-footer {
    border-bottom: none;
}

table {
    border-collapse: collapse !important;
    border-collapse: separate !important;
    border-spacing: 0 12px !important;
}
/*
.MuiBox-root-32,.MuiBox-root-33 {
    padding: 0px !important;
}*/

.table-hour, .sale-date .date span {
    position: relative;
    margin-left: 25px;
    padding-left: 19px;
    color: #a6a6a6;
}

.table-hour {
    padding-left: 15px;
    margin-left: 20px;
}

.table-hour::after, .sale-date .date span:after {
    content: "";
    position: absolute;
    left: 0;
    background: url("../images/icons/icon-clock-grey.png") no-repeat center / cover;
    height: 12px;
    width: 12px;
    top: 7px;
}

.sale-date .date span {
    font-size: 16px;
}

.sale-date .date span::after {
    top: 6px;
}

footer {
    background: #F3F3F3;
    height: 100px;
    margin-left: 90px;
    z-index: 999;
    position: relative;
}

footer img {
    height: 41px;
}

footer .site-container {
    height: 100%;
}

footer li {
    font-size: 14px;
    margin-left: 40px;
}

footer a {
    color: #434343;
}

.mb-100{
    margin-bottom: 100px !important;
}

.custom-checkbox .custom-control {
    display: flex;
    align-items: center;
}

.custom-checkbox .custom-control-label {
    cursor: pointer;
    margin-left: 10px;
    /* display: flex;
    align-items: center !important; */
}

.custom-control-label::before, table.datatable tbody td.select-checkbox::before, table.datatable tbody th.select-checkbox::before {
    border: solid 3px #404040;
    width: 22px !important;
    height: 22px !important;
    left: 0px !important;
    top: 50% !important;
    position: absolute !important;
    margin-top: -11px !important;
    border-radius: 6px !important;
}

table.dataTable tbody td.select-checkbox::before, table.dataTable tbody th.select-checkbox::before {
  left: 50%;
  margin-left: -11px;
}

td.select-checkbox::before, th.select-checkbox::before {
  border: solid 3px #404040;
  width: 22px;
  height: 22px;
  left: 0;
  top: 50%;
  position: absolute;
  margin-top: -11px;
  border-radius: 6px !important;
}


table.datatable tbody td.select-checkbox, table.datatable tbody th.select-checkbox, table.datatable thead th:first-child {
    width: 6% !important;
    position: relative;
    cursor: pointer;
}

table.datatable tbody td.select-checkbox::before, table.datatable tbody th.select-checkbox::before {
    left: 50%;
    margin-left: -11px;
}

table.datatable tbody .selected td.select-checkbox::before, table.datatable tbody .selected th.select-checkbox::before {
    border: 0;
}

table.datatable tr.selected td.select-checkbox::after, table.datatable tr.selected th.select-checkbox::after {
    left: 50% !important;
    margin-left: -11px;
}

.circle-checkbox .custom-control-label::before {
    border-radius: 50% !important;
    border: solid 3px #404040 !important;
    background-image: none;
    background: transparent !important;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: transparent;
    background-color: transparent;
}

.footer-right .nav {
    display: block;
    text-align: center;
}

footer img {
    height: 41px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after, table.datatable tr.selected td.select-checkbox::after, table.datatable tr.selected th.select-checkbox::after {
    background-image: url(../images/icons/check-red.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background: transparent;
    height: 22px;
    width: 22px;
    left: 0px;
    top: 50%;
    margin-top: -11px;
    content: "";
}

.circle-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: none !important;
    background: #00d7c5;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-top: -6px;
    left: -25px;
}

.horizontal-detail-box .custom-control.custom-checkbox {
    margin-left: -42px;
}

table.datatable tbody tr.selected {
    background: #F7F7F7 !important;
}

.actions-bar {
    padding-bottom: 20px;
}

.vertical-detail-box .supbox, .vertical-detail-box .subbox, .side-box, .login-box, .info-box {
    border-radius: 16px;
    background: #FAFAFA;
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.15);
    padding: 30px 20px;
    position: relative;
    z-index: 2;
}

.padding-login.row{
    height: 400px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.main-home{
    margin-left: 0px !important;
}

.info-box {
    width: 318px;
    min-height: 224px;
    background-attachment: #fff;
}

.info-box-home {
  text-align: center;
  padding: 0 40px;
  margin-bottom: 60px;
}
.info-box-home img {
  height: 60px;
}

.info-box-home h3 {
  font-size: 31px !important;
  margin: 30px 0 10px;
  color: #5A5A5A;
}

.info-box-home p {
  margin-bottom: 25px;
  font-size: 22px !important;
  color: #7D7D7D;
}

.upload-progress {
    width: 100%;
    height: 4px;
    background: #dfdfdf;
    border-radius: 2px;
    position: relative;
}

.upload-progress::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    height: 4px;
    background: #34C2D0;
    border-radius: 2px;
}

.vertical-detail-box .supbox, .vertical-detail-box .subbox {
    padding: 30px;
}

.side-box {
    background: #FFFFFF;
}

.vertical-detail-box .subbox {
    margin-top: -20px;
    z-index: 1;
    position: relative;
    padding-top: 50px;
}

.horizontal-detail-box .box-section-top h3.custom-checkbox, .page-container, .red-icon-list li {
    position: relative;
}

.horizontal-detail-box {
    background: #fff;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 8px;
    position: relative;
}

.horizontal-detail-box.collapsable-box a {
    display: none;
}

.horizontal-detail-box.collapsable-box.open a {
    display: block;
}

.collapsable-details-button {
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    z-index: 999;
    left: 31px;
}

.horizontal-detail-box.collapsable-box.open .collapsable-details-button span {
    display: none;
}

.collapsable-details-button span {
    position: relative;
    padding-left: 23px;
}

.collapsable-details-button::before {
    content: "";
    background: url(../images/icons/icon-angle-down.png) no-repeat;
    position: absolute;
    top: 12px;
    left: 0;
    height: 8px;
    width: 14px;
    background-size: cover;
}

.horizontal-detail-box.collapsable-box.open .collapsable-details-button::before {
    background: url(../images/icons/icon-angle-up.png) no-repeat;
    background-size: cover;
}

.horizontal-detail-box.collapsable-box.open .collapsable-details-button {
    bottom: 49px;
}

.horizontal-detail-box .box-section-top h3.custom-checkbox::before {
    content: "";
    left: -33px;
    top: 21px;
}

.box-section-top {
    padding: 30px 70px;
}

.box-section-bottom {
    padding: 25px 70px;
}

.box-section-bottom {
    border-radius: 0 0 16px 16px;
    background-color: #f9f9f9;
}

.collapsable-box .box-section-bottom {
    display: none;
}

.horizontal-detail-box .product-price, .vertical-detail-box .product-price {
    font-size: 40px;
    margin-top: 9px;
}

.horizontal-detail-box .product-price sub, .vertical-detail-box .product-price sub {
    font-weight: 300;
    position: relative;
    top: -1px;
    font-size: 24px;
}

.vertical-detail-box .product-price sub {
    margin-left: 2px;
}

.horizontal-detail-box img {
    width: 100px;
    height: 70px;
    margin-left: 25px;
}

.info-items h3 {
    font-size: 24px;
}

.box-section-bottom .info-items span {
    display: block;
}

.contenedor-datos-iframe{
    padding: 1.5rem;
}

.underline-text {
    text-decoration: underline;
}

.go-back img, .edit-user img {
    width: 18px;
}

.go-back, .edit-user {
    background: #f9f9f9;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 12px;
}

.actions-bar p span {
    font-size: 24px;
    margin-right: 10px;
}

.vertical-box-section span {
    display: block;
}

.vertical-box-section {
    padding: 0 0 20px 0;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 1px #D8D8D8 solid;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #0A4259 !important;
}

.MuiButton-containedPrimary:hover {
    background-color: #0A4259 !important;
}

.vertical-box-section:last-child {
    padding: 0;
    position: relative;
    margin: 0;
}

.vertical-box-section:last-child::after {
    content: none;
}

.product-type {
    font-size: 24px;
    font-weight: 700;
}

.award-badge {
    height: 25px;
    margin-left: 20px;
}

.general-cot-details span {
    font-size: 14px;
    margin-bottom: 8px;
}

.general-cot-details .phone, .general-cot-details .mail {
    color: #6D7278;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
    display: block;
}

.general-cot-details .mail {
    font-weight: 300;
}

.general-cot-details .city {
    color: #000;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 0;
}

.general-cot-details .pay-detail {
    font-size: 14px;
    color: #6D7278;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 5px;
}

.agent-info-texts h4 {
    font-size: 16px;
    font-weight: 700;
}

.agent-info-texts .mail {
    font-size: 12px;
    color: #434343;
}

.agent-info-texts {
    margin-left: 16px;
}

.agent-info {
    margin-top: 15px;
}

.agent-info-texts .phone {
    color: #434343;
    margin-top: 15px;
    display: block;
}

.agent-info img {
    width: 48px;
    height: 48px;
}

.filter-box .form-control, input[type="text"].form-control, input[type="mail"].form-control, input[type="password"].form-control, textarea.form-control, .switch-container .form-control, .switch-row .form-control, .number-input-container .form-control {
    font-size: 14px;
    color: #434343;
    background: #f9f9f9;
    border: none;
    height: 40px;
    padding: 5px 10px;
}

.switch-row .form-control, .switch-container .form-control {
    margin-top: -10px;
    margin-left: 20px;
    width: 80px;
}

.number-input-container .form-control {
    margin-left: 20px;
    width: 80px;
    height: 40px;
    border-radius: 0;
    padding: 0 10px;
    margin-top: 0;
}

.accordion-switch input[type="text"].form-control {
    color: #A2A2A2;
}

textarea.form-control {
    height: 100px;
}

.filter-box .form-group, .side-box .form-group {
    margin-bottom: 25px;
}

select.custom-dropdown, .pc-element.pc-trigger, .select-embarazo, .form-control.color-select {
    padding: 5px 15px 5px 10px;
    font-size: 14px;
    height: 40px;
    background: url(../images/icons/icon-angle-down.png) #f9f9f9 no-repeat;
    -webkit-appearance: none;
    width: 100%;
    border: none;
    background-position-x: calc(100% - 15px);
    background-position-y: center;
    background-size: 15px;
    color: #A2A2A2;
    cursor: pointer;
}

.picker.select-embarazo {
    width: 118px;
    position: absolute;
    margin-top: -52px;
    margin-left: 140px;
    padding: 0;
}

.action-buttons button {
    background: none;
    padding: 0;
    border: none;
}

.bordered-button.text-grey-lighter {
    border: 2px #DFDFDF solid;
    color: #DFDFDF;
}

.action-buttons button:hover {
    background: none;
}

.btn-secondary.focus, .btn-secondary:focus {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    border: none;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
}

/* .action-buttons button:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    border: none;
} */

.custom-control-input:focus:not(:checked)~.custom-control-label::before, .custom-control-input:focus:not(:checked)~.custom-control-label::after {
    box-shadow: none;
    border-color: #434343;
}

.accordion-switch .card-body .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border: 3px #A2A2A2 solid;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.tooltip-inner {
    background-color: #192043;
    font-size: 12px;
    height: 24px;
    width: 92px;
    padding-top: 2px;
    position: relative;
    top: -5px;
    border-radius: 2px;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #192043 !important;
    top: -8px;
}

.button-transparent-icon {
    background: transparent;
    border: none;
    font-size: 14px;
    align-items: center;
    position: relative;
    padding-left: 35px;
    height: 27px;
    cursor: pointer;
    width: auto;
    display: inline-flex;
}

.button-transparent-icon::before {
    content: "";
    width: 24px;
    height: 24px;
    background: url(../images/icons/icon-more-grey.png) no-repeat center / cover;
    position: absolute;
    top: 1px;
    left: 0;
}

.vertical-detail-box p {
    font-size: 18px;
    margin-bottom: 5px;
}

.vertical-box-section p img, img.arrow-separator {
    width: 20px;
    margin: 0 10px;
    height: auto;
}

.underline-link {
    text-decoration: underline;
}

.color-sample {
    min-width: 20px;
    height: 20px;
    margin-left: 30px;
}

.square-input-file .custom-file-label {
  width: 100%;
  height: 100%;
  border: 2px dashed #979797;
  background: #f5f5f5;
  border-radius: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-input-file .custom-file-label img {
  width: 102%;
}

.square-input-file .custom-file-label::after {
    content: none !important;
}

.square-input-file .custom-file-label::before {
    content: "";
    background: url(../images/icons/icon-more-grey-light.png) no-repeat center / cover;
    position: absolute;
    width: 30px;
    height: 30px;
}

.custom-file {
    height: 248px !important;
}


.text-area-form{
    height: 100px !important;
}

.paid-row .form-group {
    width: 270px;
}

.custom-control {
    padding-left: 0 !important;
    padding: 10px 0px !important;
    height: auto;
    display: flex;
    align-items: center;
}


.MuiSlider-valueLabel span{
  height: auto !important;
  transform: none !important;
  border-radius: 0 !important;
  background-color: #34C2D0 !important;
}


.paid-row .custom-control.custom-radio {
    padding-left: 0 !important;
}

.paid-row .custom-control-label {
    padding-left: 30px;
}

table.polizas-table.datatable tbody tr {
    box-shadow: none;
}

table.polizas-table.datatable tbody tr:first-child td {
    padding: 20px 0 5px;
}

table.polizas-table.datatable tbody th, table.polizas-table.datatable tbody td {
    padding: 5px 0;
}

table.polizas-table {
    border-spacing: 0 !important;
}

.selected-quantity p {
    font-size: 12px;
}

.selected-quantity span {
    position: relative;
    left: 0;
    margin-left: 8px;
    top: 1px;
    height: 21px;
    line-height: 20px;
}


table span {
    display: block;
}

.table-price {
    line-height: 20px;
}

.table-price sub {
    position: relative;
    font-size: 14px;
    top: -1px;
}

.table-price sub {
    position: relative;
    font-size: 14px;
    top: -1px;
}

.wizard-nav ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.root-wizard .nav.nav-pills li:last-child {
    visibility: hidden;
}

.root-wizard .nav.nav-pills a {
    width: 200px;
    background: #DFDFDF;
    height: 25px;
    display: block;
    text-align: center;
    font-size: 14px;
    color: #434343;
    border-radius: 50px;
    margin-bottom: 10px;
    line-height: 25px;
    text-align: right;
    padding: 0 20px;
}

.root-wizard .nav.nav-pills a.active {
    background: #34C2D0 !important;
    color: #fff;
}

.root-wizard .nav.nav-pills li:hover a, .root-wizard .nav.nav-pills li.done a {
    text-decoration: none !important;
    background: #79D4DE;
    color: #fff;
}

.input-side {
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 2px;
}

.info-text {
    position: relative;
    padding-left: 23px;
}

.info-text::after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../images/icons/icon-info.png) no-repeat center / cover;
    position: absolute;
    top: 1px;
    left: 0;
}

.close-button {
    height: 40px;
    width: 40px;
    background: #F9F9F9;
    border-radius: 50%;
    text-align: center;
    line-height: 37px;
    cursor: pointer;
    margin-left: 20px;
}

.transparent-row {
    padding: 10px 0 5px;
}

.transparent-row.odd {
    margin: 0 -30px;
    background: rgba(202, 199, 199, 0.2);
    padding: 10px 30px 5px;
}

.title-term-conditions {
    margin-top: 200px;
    margin-bottom: 125px;
}

.slider-handle::after {
    content: attr(aria-valuenow);
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 7px;
    font-weight: 300;
    color: #fff;
    background-color: #34C2D0;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 35px;
    height: 20px;
    letter-spacing: 0.5px;
}

.slider-handle::before {
    content: "";
    top: -10px;
    left: 50%;
    margin-left: -3px;
    border-width: 3px 3px 0;
    border-top-color: #34C2D0 !important;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-top-color: transparent;
    border-style: solid;
}

.slider.slider-horizontal {
    width: 100%;
    height: 20px;
}

.slider-selection {
    border-radius: 0;
    box-shadow: none;
}

.slider.slider-horizontal .slider-tick, .slider.slider-horizontal .slider-handle {
    margin-left: 0;
}

.slider-tick {
    box-shadow: none;
    top: 10px;
    height: 10px;
    width: 3px;
    border-radius: 0 !important;
    background-image: none !important;
    background: #E6E6E6;
    border-radius: 0 0 5px 5px !important;
}

.slider-track {
    background-image: none;
    box-shadow: none;
    border-radius: 0;
    background: #E6E6E6;
}

.slider-selection.tick-slider-selection {
    background-image: none;
    background: #34C2D0;
}

.slider.slider-horizontal .slider-track {
    height: 5px;
    margin-top: -5px;
    top: 50%;
    left: 0;
    width: calc(100% + 3px);
}

.slider-handle {
    width: 15px;
    height: 14px;
    background-color: #34C2D0;
    background-image: none;
    box-shadow: none;
    border-radius: 5px !important;
    margin-left: -6px !important;
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    padding-top: 0;
    display: inline-block;
    text-align: center;
    font-size: 9px;
    letter-spacing: 0.5px;
    color: #AAAAAA;
}

.slider.slider-horizontal .slider-tick-label-container {
    margin-top: 18px;
}

.slider-tick-label:first-child {
    margin-left: 2px;
}

.slider-tick-label:last-child {
    margin-left: -5px;
}

.modal-content {
    border-radius: 15px;
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.15);
}

.modal-dialog {
    max-width: 420px !important;
}

.modal-body {
    padding: 25px;
}

.modal-backdrop.show {
    opacity: .9;
    background: #fff;
    display: none !important;
}

.modal-content {
    border-radius: 15px;
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.15);
    border: none;
}

hr {
    border-top: 1px #D8D8D8 solid;
    margin: 25px 0;
}

.accordion-details .card, .red-icon-list li {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px #e1e1e1 solid !important;
    background: transparent;
}

.accordion-details .card-body {
    padding: 0 70px 35px 70px;
    color: #6d7278;
}

.accordion-details .card-header {
    background-color: transparent;
    border-bottom: none;
}

.accordion-details .card-header a::after {
    content: "";
    background: url(../images/icons/icon-angle-up.png) no-repeat center / cover;
    position: absolute;
    height: 9px;
    width: 16px;
    top: 30px;
    right: 25px;
}

.accordion-details .card-header a.collapsed:after, .accordion-switch .card-header a.collapsed::after {
    background: url(../images/icons/icon-angle-down.png) no-repeat center / cover;
}

.card-header {
    padding: 0;
}

.card-new-design{
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border: 1px solid #c8ced3;
}

.accordion-details .card-header h5 a, .red-icon-list li {
    width: 100%;
    display: block;
    padding: 25px 25px 25px 70px;
    color: #434343;
    font-size: 18px;
}



.accordion-details .card-header a::before, .red-icon-list li::before {
    position: absolute;
    content: "";
    background: url(../images/icons/icon-accordion.png) no-repeat center;
    height: 22px;
    width: 22px;
    left: 25px;
    top: 23px;
}

.red-icon-list li::before {
    background: url(../images/icons/icon-red-li.png) no-repeat center;
    top: 27px;
}

.accordion-switch .card-header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    background: #f9f9f9;
    border: none;
    padding: 15px 0 15px 30px;
}

.accordion-switch .card {
    border: none;
}

.accordion-switch .card-header a::after {
    content: "";
    background: url(../images/icons/icon-angle-up.png) no-repeat center / cover;
    height: 9px;
    width: 16px;
    display: block;
    position: relative;
    margin-left: -8px;
    left: 50%;
    top: 50%;
    margin-top: -5px;
}



.accordion-switch .card-body .custom-control-label::before {
    border: 3px #A2A2A2 solid;
}

.accordion-switch .card-body {
    padding: 15px 0 15px 30px;
}

.accordion-switch .card-body.disabled::before {
    content: "";
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    z-index: 999;
    position: absolute;
    left: 0;
}

.row.switch-row p {
    margin-bottom: 0;
}

.row.switch-row div {
    align-items: center;
    display: flex;
}

.row.switch-row {
    margin-bottom: 10px;
}

.row.switch-row:last-child {
    margin-bottom: 0;
}

.switch-row {
    display: flex;
    align-items: center;
}

.switch-row>div, .accordion-switch .card-header>div {
    padding-right: 20px;
}

.switch-row>div:nth-child(1), .accordion-switch .card-header>div:nth-child(1) {
    width: 40%;
}

.switch-row>div:nth-child(2), .accordion-switch .card-header>div:nth-child(2) {
    width: 25%;
}

.switch-row>div:nth-child(3), .accordion-switch .card-header>div:nth-child(3) {
    width: 15%;
}

.switch-row>div:nth-child(4), .accordion-switch .card-header>div:nth-child(4) {
    width: 20%;
    text-align: right;
}

.switch-row p {
    margin-bottom: 0;
}

.switch-row {
    margin-bottom: 10px;
}

.switch-row:last-child {
    margin-bottom: 0;
}

.accordion-switch .card-header .switch {
    position: relative;
    margin-left: -22.5px;
    right: 40%;
}

.switch.switch-small {
    width: 31px;
    position: relative;
    right: 35%;
    margin-left: -16px;
}

.accordion-switch .card-header a {
    width: 40px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
}

.switch-container .switch {
    width: 45px;
    position: relative;
    left: 50%;
    margin-left: -23px;
    top: 50%;
    margin-top: -12px;
}

.search-field {
    display: none;
}

.login-box-container {
    width: 318px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 50%;
    margin-left: -159px;
}

.login-box {
    padding: 25px 20px;
}

.login-box input[type="password"].form-control::after {
    content: "";
    position: absolute;
    left: 0;
    background: url("../images/icons/icon-eye.png") no-repeat center / cover;
    height: 12px;
    width: 12px;
    top: 7px;
}

.password-image {
    width: 24px;
    position: absolute;
    top: 8px;
    right: 10px;
}

.login-notification, .table-notification {
    background: #434343;
    color: #fff;
    font-size: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 10px;
}

.table-notification {
    height: 72px;
    padding: 0 25px;
    justify-content: space-between;
    font-size: 18px;
}

.login-notification span, .table-notification span {
    margin-left: 23px;
    cursor: pointer;
}

.grey-container {
    margin: 0 -30px;
    padding: 20px 30px 150px;
    background: #F9F9F9;
}

.grey-container.middle {
    margin-top: -163px;
    padding-top: 163px;
}

.wizard-top-bar, .table-page-header {
    height: 100px;
    padding: 0 30px;
    display: flex;
    align-items: center;
}

.table-page-header {
    height: 144px;
    margin-top: 113px;
    background: #F9F9F9;
}

.award-icon {
    width: 17px !important;
    height: auto !important;
    margin-left: 14px !important;
    margin-top: -8px;
}

.vertical-box-section .award-icon {
    width: 12px !important;
}

span.placeholder-icon {
    position: absolute;
    top: 13px;
    z-index: 999;
    left: 10px;
    font-size: 12px;
    color: #A2A2A2;
}

input.form-control.placeholder-icon {
    padding-left: 20px;
}

.user-info {
    cursor: pointer;
}

.header-notifications {
    top: 20px;
    position: absolute;
    right: 30px;
    z-index: 999;
    transition: all 0.5s ease;
}

.user-notifications, .general-notifications {
    width: 430px;
    background: #fff;
    padding: 25px 25px 15px;
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.logout {
    margin-top: -10px;
}

img.avatar-agent-icon {
    width: 48px;
    min-width: 48px;
}

img.avatar-user-icon {
    width: 64px;
}

.notification-item {
    cursor: pointer;
    margin: 0 -25px;
    padding: 10px 25px;
    position: relative;
}

.notification-item:hover {
    background: #FAFAFA;
}

.close-notification {
    position: absolute;
    right: 30px;
    background: url("../images/icons/icon-x.png") no-repeat center / cover;
    height: 12px;
    width: 12px;
    top: 50%;
    margin-top: -6px;
}

.details-pago {
    display: none;
    border-top: 1px #D8D8D8 solid;
    border-bottom: none;
    margin-bottom: 0;
    padding-top: 20px !important;
}

table.grouped-table {
    background: transparent !important;
    margin-top: -10px;
}

table.grouped-table thead {
    background: #F9F9F9 !important;
    position: relative;
}

table.grouped-table thead::before {
    content: "";
    position: absolute;
    height: 70px;
    width: 100%;
    top: 0;
    z-index: 999;
}

table.grouped-table thead th {
    padding: 14px 15px 14px 34px !important;
}

.grouped-table .group {
    position: relative;
    box-shadow: none;
}

.grouped-table .group .arrow-row .arrow {
    width: 14px;
    height: 8px;
    background: url("../images/icons/icon-angle-down.png") no-repeat center / cover;
    position: absolute;
    top: 33px;
    right: 30px;
    cursor: pointer;
}

.grouped-table .group .arrow-row.droped .arrow {
    background: url("../images/icons/icon-angle-up.png") no-repeat center / cover;
}

.arrow-row {
    display: table;
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
}

.grouped-table .group .arrow.droped {
    background: url("../images/icons/icon-angle-up.png") no-repeat center / cover;
}


.grouped-table .custom-checkbox {
    display: table;
    padding: 10px 0;
    position: relative;
    z-index: 999;
}

.modal-dialog.mw-400 {
    max-width: 400px !important;
}

.picker .pc-element, .picker .pc-trigger {
    position: relative;
}

.pc-element {
    margin: 0 12px 12px 0;
    padding: 0 45px 0 15px;
    border: 1px solid #979797;
    color: #434343;
    line-height: 37px;
    list-style: none;
    height: 40px;
    border-radius: 50px;
    display: table;
    font-size: 14px;
}

.picker .pc-element .pc-close, .picker .pc-trigger .pc-close {
    position: absolute;
    top: 1px;
    right: 17px;
    font-size: 12px;
    cursor: pointer;
}

.picker .pc-element .pc-close:after, .picker .pc-trigger .pc-close:after {
    content: '\2716';
}

.picker .pc-select {
    position: relative;
    display: inline-block;
    width: 100%;
}

.pc-element.pc-trigger {
    display: flex !important;
    align-items: center;
    color: #434343;
    border: none;
}

.selectedhidden {
    color: #A2A2A2;
}

.picker .pc-select .pc-list {
    position: absolute;
    text-align: left;
    left: 0;
    top: 40px;
    width: 100%;
    z-index: 9999;
    background-color: #ffffff;
    min-height: 25px;
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0 0 16px 16px;
    border-top: 1px #434343 solid;
    color: #A0A0A0;
    padding: 10px 0;
}

.picker .pc-select .pc-list input[type="search"] {
    width: 100%;
    outline: none;
    color: #666666;
    border: none;
    position: relative;
    background-color: #DDDDDD;
    border-bottom: 2px solid #666;
    padding-left: 8px;
}

.picker .pc-select .pc-list ul {
    margin: 0;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
}

.picker .pc-select .pc-list li {
    display: block;
    list-style: none;
    padding: 12px 15px;
    cursor: pointer;
    color: #A6A6A6;
    word-wrap: break-word;
    font-size: 14px;
}

.picker .pc-select .pc-list li:hover {
    background: #F9F9F9;
}

.picker .pc-select .pc-list li.not-found {
    font-style: italic;
    text-align: center;
    cursor: default;
}

.picker .pc-select .pc-list li .searched {
    font-weight: bold;
}

.picker .pc-select .pc-trigger {
    border-radius: 0;
    margin-bottom: 0;
}

.picker.side-options {
    display: flex;
}

.picker.side-options .pc-select {
    width: 230px;
    margin-right: 90px;
}

.picker.side-options .pc-element {
    display: table;
}

.pc-elements-selected {
    margin-top: 10px;
}

.picker .pc-elements-selected .pc-element.operation-country {
  cursor: pointer;
}

.picker .pc-elements-selected .pc-element.operation-country.selected {
  background-color: #deeafd;
}

.accordion-switch .picker .pc-elements-selected {
    display: none;
}

.input-group-addon {
    display: flex;
    align-items: center;
}

.input-group-addon {
    display: flex;
    align-items: center;
    background: #f9f9f9;
    margin-left: -4px;
    padding-left: 10px;
}

.picker {
    min-width: 180px;
    max-width: 100%;
}

.footer-cliente {
    margin-left: -138px;
    margin-top: 100px;
}

.filter-drop .col-6, .filter-drop .col-12 {
    padding: 10px;
}

.destino-intermedio .close-button {
    display: none;
}

.destino-intermedio.clone .close-button {
    display: block;
}

.color-selector-field {
  padding: 0;
  width: 230px !important;
  min-width: 20px;
  height: 30px;
  border-radius: 0;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
}

.color-selector-button {
    padding: 5px 15px 5px 10px !important;
    font-size: 14px !important;
    height: 40px !important;
    background: url(../images/icons/icon-angle-down.png) #f9f9f9 no-repeat !important;
    -webkit-appearance: none !important;
    width: 100% !important;
    border: none !important;
    background-position-x: calc(100% - 15px) !important;
    background-position-y: center !important;
    background-size: 15px !important;
    color: #A2A2A2;
    cursor: pointer !important;
    display: block;
    margin-right: 25px;
    line-height: 31px;
}

.color-selector {
    display: flex;
    align-items: center;
}

.form-group.date-group {
    position: relative;
}

.form-group.date-group img {
    width: 20px;
    margin-right: 0;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    left: 10px;
}

.form-group.date-group .form-control.date {
    padding-left: 40px !important;
}

.title-terms-policy {
    color: #0A4259;
    font-size: 16px !important;
    font-weight: 800 !important;
}

.title-final-terms-policy {
    color: #2c4664;
    font-size: 18px !important;
    font-weight: 800 !important;
}

.text-terms-policy{
    color: #494C52;
    font-size: 15px;
    font-weight: 500 !important;
}

.table-term-policy {
    font-family: arial, sans-serif;
    border-collapse: collapse !important;
    width: 95%;
}

.table-term-policy td, th {
    border: none i !important;
    text-align: left;
    padding: 8px;
}

.table-term-policy tr:nth-child(even) {
    background-color: #dddddd;
}

input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.date-tag {
    position: absolute;
    top: 9px;
    right: 10px;
    padding: 0 8px;
}

.table-container {
    overflow: auto;
}

.responsive-menu {
    display: none;
}

table.datatable.grouped-table thead .sorting_asc {
    background-image: none;
}

table.datatable.grouped-table thead th:nth-child(2) {
    background-image: url("../images/icons/icon-arrow-up.png");
}


.react-bootstrap-daterangepicker-container{
    width: 102% !important;
}

.MuiCircularProgress-colorPrimary{
    color: #3283ff !important;
}

.sc-AxjAm.bSpxGd{
    width: 100% !important;
    font-size: 14px;
    color: #434343;
    background: #f9f9f9;
    border: none;
    height: 40px;
    padding: 5px 30px;
}

.react-tel-input .flag-dropdown {
  border: none!important;
}

#field-wrapper, #card-number, #card-expiry, #cvc  {
    top: -4px;
    color: #434343;
    background: #f9f9f9;
}

.sc-AxiKw.hNyjIz{
    top: 2px;
}

.titulo-eliminar {
  font-size: 20px !important;
  font-weight: bold !important;
}

.icon-select{
    top: 18px;
    right: 25px;
    position: absolute;
}


/* Responsive Styles */

@media (max-width: 1500px) {
    .filter-field, .search-box, .group-field {
        margin-right: 40px;
    }
    .group-field.bordered, .search-box.bordered, .groupby-drop {
        width: 200px;
    }
    .picker {
        min-width: auto;
        max-width: 100%;
    }
}

@media (max-width: 1250px) {
    .actions-bar .mobile-d-block {
        display: block !important;
    }
    .action-buttons {
        margin-top: 20px;
    }
    .table-head-actions .action-buttons {
        margin-top: 0;
    }
    .search-box {
        width: 230px;
    }
}

@media (max-width: 1100px) {
    .filter-field, .search-box, .group-field {
        margin-right: 28px;
    }
    .accordion.accordion-switch {
        margin-bottom: 40px;
    }
}

@media (max-width: 900px) {
    .filter-field, .search-box, .group-field {
        width: 150px !important;
    }
}

@media (max-width: 767px) {
    .main-sidebar {
        display: none;
    }
    header.header {
        height: 80px;
    }
    .user-info {
        margin-right: 50px;
    }
  .responsive-menu {
    background: #3283ff;
    position: fixed;
    padding-top: 22%;
    height: 50%;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    transition: 1s ease;
  }
    .responsive-menu-icon {
        height: 24px;
        top: 50%;
        right: 15px;
        position: relative;
        margin-top: -12px;
    }
    .menu-line {
        position: absolute;
        top: 50%;
        background: #3283ff;
        width: 100%;
        height: 4px;
        margin-top: -2px;
    }
    .button-responsive-menu.open .menu-line, .button-responsive-menu.open .menu-line::before, .button-responsive-menu.open .menu-line::after {
        background: #fff !important;
    }
    .menu-line::before, .menu-line::after {
        content: "";
        height: 4px;
        top: -11px;
        position: absolute;
        width: 100%;
        right: 0px;
        background: #3283ff;
    }
    .menu-line::after {
        top: 11px;
    }
    .main-content {
        background: #F9F9F9;
        margin-left: 0;
        padding-bottom: 90px;
    }
    .site-container {
        margin-top: 80px;
    }
    .table-page-header {
        height: 100px;
        margin-top: 80px;
    }
    .site-container {
        padding: 30px 30px 0;
    }
    .mb-70 {
        margin-bottom: 35px;
    }
  .responsive-menu {
    background: #3283ff;
    position: fixed;
    padding-top: 22%;
    height: 60%;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    transition: 1s ease;
  }
  .menu-active-responsive{
    background: #fff;

  }
    .responsive-menu.open {
        display: flex;
    }
    body.open-menu {
        overflow: hidden;
    }
    .responsive-menu li {
        color: #fff;
        list-style: none;
        padding: 10px 0;
        text-align: left;
    }
    .responsive-menu li a {
        color: #fff;
        font-size: 25px;
    }

    .menu-active-responsive a{
      color: #3283FF !important;
    }

    .header-responsive-title{
      font-size: 14px !important;
      font-weight: 600 !important;
      letter-spacing: 0 !important;
      line-height: 18px !important;
      text-align: center !important;
      color: #3283FF !important;
      position: relative;
      right: 20%;
    }



    .responsive-menu li a:hover {
        text-decoration: none;
    }
    header .site-container, footer .site-container {
        padding: 0 20px;
    }
    footer {
        height: auto;
        margin-left: 0;
        padding: 15px 0;
    }
    footer .site-container {
        display: block !important;
        text-align: center;
    }
    .footer-right .nav {
        display: block;
        text-align: center;
    }
    footer li {
        margin-left: 0;
    }
    footer img {
        height: 41px;
        margin-bottom: 10px;
    }
    .mobile-p-0 {
        padding: 0 !important;
    }
    .mobile-pb-60 {
        padding-bottom: 60px !important;
    }
    .mobile-mb-40 {
        margin-bottom: 40px;
    }
    .mobile-mb-20 {
        margin-bottom: 20px;
    }
    .mobile-mb-10 {
        margin-bottom: 10px;
    }
    .mobile-m-0 {
        margin: 0 !important;
    }
    .mobile-mt-20 {
        margin-top: 20px !important;
    }
    .mobile-mt-40 {
        margin-top: 40px !important;
    }
    .mobile-mt-10 {
        margin-top: 10px !important;
    }
    .mobile-ml-0 {
        margin-left: 0 !important;
    }
    .title-term-conditions {
        margin: 50px 0;
    }
    .mobile-d-none {
        display: none;
    }
    .header-info {
        padding: 30px;
        margin-top: 80px;
    }
    .mobile-d-block {
        display: block !important;
    }
    .box-section-top, .box-section-bottom {
        padding: 20px;
    }
    .horizontal-detail-box {
        margin-bottom: 30px;
    }
    .accordion-details .card-header a::before, .red-icon-list li::before {
        left: 0;
    }
    .accordion-details .card-header h5 a, .red-icon-list li {
        padding: 20px 50px 20px 40px;
        font-size: 15px;
    }
    .accordion-details .card-body {
        padding: 0 0 20px 0;
    }
    .wizard-nav ul {
        align-items: flex-start;
    }
    .box-section-top .custom-control-label.font-32 {
        font-size: 20px !important;
        line-height: 25px;
    }
    .tables-tabs .nav-link {
        padding: 0 15px 10px;
    }
    .container-tables-tabs {
        padding: 0 10px;
    }
    .header-info .go-back {
        display: none;
    }
    .tables-tabs .nav-link {
        padding: 0 12px 10px;
        font-size: 14px;
    }
    .days-count {
        margin-top: 10px;
        justify-content: flex-end;
    }
    .destino-intermedio.clone .close-button {
        margin-top: 20px;
    }
    .filter-field, .search-box, .group-field {
        width: 100% !important;
        margin-bottom: 25px;
    }
    .table-head-actions .action-buttons {
        justify-content: flex-end;
    }
    .filter-drop, .groupby-drop {
        margin-top: 0;
    }

    .tabla-filtros.d-flex,.head-flex.d-flex{
        display: block ;
    }

    .mobile-ml-35 {
        margin-left: 35px !important;
    }

    .collapsable-details-button {
        bottom: auto;
        top: 65px;
        right: 30px;
        left: auto;
    }

    .mobile-w-100 {
        width: 100% !important;
    }

    .mobile-ml-35 {
        margin-left: 35px !important;
    }

    .mobile-mt-0 {
        margin-top: 0 !important;
    }

    .cotizador-ranges-sidebox .supbox {
        background: transparent !important;
        box-shadow: none;
    }

    .container-sidebox-filter-options {
        display: flex !important;
        margin-top: 10px;
    }

    .go-back-container, .container-sidebox-filter-options {
        background: #fff;
        box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.15);
        margin: -30px -30px 30px;
        padding: 10px 20px;
    }
    .mobile-mb-0 {
        margin-bottom: 0 !important;
    }

    .mobile-p-0 {
        padding: 0 !important;
    }

    .react-bootstrap-daterangepicker-container{
        width: 102% !important;
    }


    /* End 767 */

}

@media (max-width: 650px) {
    .MuiMobileStepper-progress {
        width: 40% !important;
    }

    .d-sm-none{
        display: none;
    }

    .text-small-center{
        text-align: center;
    }

    .accordion.accordion-switch {
        width: 650px;
        margin-bottom: 0;
    }
    .mobile-small-elements .custom-control-label {
        font-size: 20px !important;
    }

    .horizontal-detail-box .font-24 {
        font-size: 18px !important;
    }

    .horizontal-detail-box .product-price, .vertical-detail-box .product-price {
        font-size: 25px;
        margin-top: 10px !important;
    }

    .cotizador-ranges-sidebox {
        font-size: 14px !important;
    }

    .vertical-detail-box .supbox, .vertical-detail-box .subbox {
        padding: 20px;
        margin-bottom: 50px;
    }

    .horizontal-detail-box .font-32 {
        font-size: 22px !important;
        line-height: 25px;
    }

}

@media (max-width: 500px) {
    .horizontal-detail-box.collapsable-box.open .box-section-top .d-flex.justify-content-between.align-items-center.mt-45.mobile-mb-40 {
        display: block;
    }
    .horizontal-detail-box.collapsable-box.open .agent-group.d-flex.align-items-center {
        justify-content: right;
        margin-top: 25px;
    }
}

@media (max-width: 450px) {
    .contenedor-datos-iframe, .site-container{
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    .site-logo img {
        height: 40px;
    }
    .user-info-texts {
        margin: 0 10px 0 0;
    }
    footer li {
        font-size: 13px;
        margin-left: 0;
        padding: 2px 0;
    }
    .site-container {
        padding: 30px 20px 0;
    }
    .filter-drop.open {
        width: calc(100% - 40px);
    }
    .button-large, .button-medium, .button-bordered-dark, .bordered-button, .btn-responsive {
        min-width: auto !important;
    }
    header .site-container, footer .site-container {
        padding: 0 15px;
    }
    .button-responsive-menu {
        right: 15px;
    }
    .user-info {
        margin-right: 40px;
    }
    .button-responsive-menu {
        width: 28px;
    }
    .table-page-header {
        height: 80px;
        margin-top: 80px;
    }
}

.nav-tabs .nav-link.active {
  color: #3283FF!important;
  background: #f7f7f7!important;
  border-color: #c8ced3!important;
  border-bottom-color: #fff!important;
}

.color-green{
  color: green;
}

.color-yellow{
  color: #fdd323;
}